import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { graphql } from 'gatsby';

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Section from "../components/section";
import CampaignItem from "../components/template/campaign-item";
import ImageColumns from "../components/image-columns";
//import Image from "../components/image";
//import AdvTitle from '../components/advTitle';
//import LightboxContainer from "../components/lightbox-container";
//import ImagesWithTextContainer from '../components/images-with-text-container';
import Partners from "../components/partners";
import Blog from "../components/blog";
import OurClients from "../components/our-clients";

import "../styles/home.less";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderBanner from "../components/slider-banner";
import Signatures from "../components/signatures";

export const segments = graphql`
	query IndexNewQuery
	{
		allSegmentsJson {
			edges
			{
				node
				{
					colSize
					className
                    imageName
                    imagePath
					imageAlt
					title
				}
			}
		},
		allClientsJson
		{
			edges
			{
				node
				{
					colSize
					className
					imageName
					imagePath
					{
						childImageSharp
						{
							fluid
							{
								...GatsbyImageSharpFluid
							}
						}
					}
					imageAlt
					text
					link
				}
			}
		},
		allHelpItemsJson
		{
			edges
			{
				node
				{
					colSize
					className
					imageName
					imageAlt
					title
					description
				}
			}
		}
	}
`;

const Index = (props) => {

    // CONTENT //

    // Banner
    const slides =
    [
        {
            imagePath:"/images/slides/slide-general.jpg",
            alignment: 'right',
            textColor: 'white',
            title: "Your best ally to promote and finance good businesses",
            subtitle: "With our help, build your business development program, your innovation ecosystem, your investment club or your crowdfunding platform",
        },
    ]
    const services =
    {
        title: props.intl.formatMessage({id: 'Consulting services'}),
        description: props.intl.formatMessage({id: "In Adventurees we share with our clients the wide experience we have acquired in the world of entrepreneurship and investment. To this we add a portfolio of investors, a network of analysts, advisors and mentors, as well as training, guidance and consulting services, to help you boost your projects and turn them into successful businesses."}),
        button:
        {
            text: "Know more",
            url: '/services'
        }
    };

    const products =
    {
        title: props.intl.formatMessage({id: 'Digital platforms'}),
        description: props.intl.formatMessage({id: "Adventurees products help organizations, institutions and individuals boost their ecosystem businesses and connect them with investment. Our clients can use our technologies and analysis tools to develop their own project portfolios, analyze and finance them, digitize their work processes with entrepreneurs and mentors, connect with the community, coordinate resources, give visibility and much more."}),
        button:
        {
            text: "Know more",
            url: '/products'
        }
    };

    const investment =
    {
        title: props.intl.formatMessage({id: 'Channeling of your investment'}),
        description: props.intl.formatMessage({id: "From Adventurees we have created an investment platform authorized by the CNMV, Adventurees.com, through which we offer the opportunity to invest in the best projects selected by us. Also, to facilitate investments, we also take care of legally vehicular operations, providing greater security to companies and investors."}),
        button:
        {
            text: "Know more",
            url: '/investment'
        }
    };

    return <Layout className="home">
        <SEO
            title={props.intl.formatMessage({id: "International business development and investment platform"})}
            description={props.intl.formatMessage({id: "Finance businesses and build with our help your business development program, your innovation ecosystem, your investment club or your crowdfunding platform"})}
            location={props.location}
            image="/images/sharing/home-products.jpg"
        />
        { /*
        <BannerBig
            className="banner banner-big"
            filename="main-header.jpg"
            title={props.intl.formatMessage({id: "Your best ally to promote and finance good businesses"})}
            text={props.intl.formatMessage({id: "With our help, build your business development program, your innovation ecosystem, your investment club or your crowdfunding platform"})}
        />
        */ }
        <SliderBanner slides={slides} />

		{ /*1.- */ }
		<Section title={props.intl.formatMessage({id: "What do we offer?"})}>
			<div className="row campaign-items-container">
				<div className="col-lg-4">
					<CampaignItem image={{name: 'home-products.jpg', alt: props.intl.formatMessage({id: 'Products'})}} campaign={products} />
				</div>
				<div className="col-lg-4">
                    <CampaignItem image={{name: 'home-services.jpg', alt: 'DemoDay'}} campaign={services} />
				</div>
				<div className="col-lg-4">
					<CampaignItem
						image={{name: 'home-investment.jpg', alt: props.intl.formatMessage({id: 'Investment'})}}
						campaign={investment}
					/>
				</div>
			</div>
		</Section>

		{/*2.- */}
        <Section title={props.intl.formatMessage({id: "Who hires our products?"})}>
            <ImageColumns columns={props.data.allSegmentsJson.edges} className="services" />
        </Section>

        {/*3.- */}
		<OurClients slim/>

		{/*4.-}
        <Section title={props.intl.formatMessage({id: "Build your own online entrepreneurship platform with your brand"})}>
            <div className="container product business-up">
                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <Image filename="products-business-up-laptop.png" />
                        <div className="free-trial-seal desktop">
                            <div className="free-trial-text">
                                {props.intl.formatMessage({id: "Try it"})}
                                <span>{props.intl.formatMessage({id: "Free"})}</span>
                                {props.intl.formatMessage({id: "for"})}
                            </div>
                            <div className="free-trial-period">
                                <span>7</span>
                                {props.intl.formatMessage({id: "days"})}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 product-features">
                        <AdvTitle title={props.intl.formatMessage({id: "BusinessUp"})} />
                        <ul>
                            <li>{props.intl.formatMessage({id: "Own web"})}</li>
                            <li>{props.intl.formatMessage({id: "Usage of your own domain"})}</li>
                            <li>{props.intl.formatMessage({id: "Brand Look&Feel"})}</li>
                            <li>{props.intl.formatMessage({id: "Customer's data and contents"})}</li>
                            <li>{props.intl.formatMessage({id: "Editable pages"})}</li>
                            <li>{props.intl.formatMessage({id: "Reception and filtering projects"})}</li>
                            <li>{props.intl.formatMessage({id: "Goldsmith® model analysis"})}</li>
                            <li>{props.intl.formatMessage({id: "Own diagnostic KPIs"})}</li>
                            <li>{props.intl.formatMessage({id: "Business document management"})}</li>
                            <li>{props.intl.formatMessage({id: "Online mentoring space"})}</li>
                            <li>{props.intl.formatMessage({id: "Business showcase"})}</li>
                            <li>{props.intl.formatMessage({id: "Events and community"})}</li>
                            <li>{props.intl.formatMessage({id: "100% autonomous administration"})}</li>
                            <li>{props.intl.formatMessage({id: "Administrator training and support"})}</li>
                            <li>{props.intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</li>
                        </ul>
                        <div className="form-trigger">
                            <p className="free-trial-trigger-text">{props.intl.formatMessage({id: "For 149€ per month"})}</p>
                            <div className="free-trial-seal mobile">
                                <div className="free-trial-text">
                                    {props.intl.formatMessage({id: "Try it"})}
                                    <span>{props.intl.formatMessage({id: "Free"})}</span>
                                    {props.intl.formatMessage({id: "for"})}
                                </div>
                                <div className="free-trial-period">
                                    <span>7</span>
                                    {props.intl.formatMessage({id: "days"})}
                                </div>
                            </div>
                            <Link to="/business-up">{props.intl.formatMessage({id: "Know more"})}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Section>

        <Section title={props.intl.formatMessage({id: "Do you need additional help?"})} className="support-items">
            <ImagesWithTextContainer elements={props.data.allHelpItemsJson.edges} className="help-items" />
        </Section>
        */ }

        {/*
        <Section title={props.intl.formatMessage({id: "Get to know our client's platforms"})}>
            <LightboxContainer columns={data.allClientsJson.edges} className="clients-platforms" />
        </Section>
        */}
        {props.intl.locale === 'es' && <Blog fetchLimit={4} title={props.intl.formatMessage({id: "Latest news"})} />}
        <Section>
            <Signatures />
        </Section>
        <Section className="mb-5" subtitle={props.intl.formatMessage({id: "This company has received a participative loan from the \"Creation and Development of Innovative Technological SMEs\" line of the Canary Islands Fund, Finance 1, and has 85% co-financing from the European Regional Development Fund from the ERDF Operational Programme for the Canary Islands 2014-2020, contributing to the fulfilment of the objectives of priority axis 1 \"Strengthening research, technological development and innovation\", Specific Objective 1. 2.1 \"Encouragement and promotion of R&D&I activities led by companies, support for the creation and consolidation of innovative companies and support for innovative public procurement\"."})}></Section>
    </Layout>
}

export default injectIntl(Index);
